import * as React from "react"
import Menu from "../components/menu"
import Footer from "../components/footer"
import { StaticImage } from "gatsby-plugin-image"
import { subtitle, title, textContainer } from "../styles"
import clsx from "clsx"
import Seo from "../components/seo"

// markup
const DetailElogPage = (props) => {
  return (
    <div> 
      <Seo />
      <Menu/>
      <div className="max-w-screen-xl mx-5 xl:mx-auto md:grid md:grid-cols-3 md:gap-8">

        <div className="max-w-screen-lg mt-12 text-center col-span-2">
          <StaticImage className="w-4/6 md:w-full"
            src="../images/elog-mockup.png"
            placeholder="blurred"
            loading="eager"
          />
        </div>

        <div className="max-w-screen-sm mt-12">
          <h1 className={ title }>
            UX/UI Design - Time tracking SaaS
          </h1>
          <div className={ clsx(textContainer, "text-justify") }>
            <p className="mb-4">eLog is individual project, for which the purpose was to design a new software as a service (SaaS) desktop web app, for companies to use internally. I presented it at Ironhack in Barcelona.</p>
            <p className="mb-4">This project was spread onto 2 weeks, one dedicated to primary research, and the second one dedicated to prototyping, testing, iterating and delivering. </p>
            <p>To carry out this project, I conducted some market research and met with some employees from different companies and positions, in order to determine the needs and then develop an MVP (Minimum Viable Product) based upon my findings. I articulated my research using the Design Thinking framework which is a method designers use in ideation and development. The method describes a human-centered, iterative design process consisting of 5 steps: Empathize, Define, Ideate, Prototype and Test.</p>
          </div>

          <div className={ textContainer }>
            <h2 className = { subtitle }> Client </h2>
            <p className="mb-4">Ironhack</p>
            <h2 className = { subtitle }> Type </h2>
            <p className="mb-4">UX/UI Design</p>
            <h2 className = { subtitle }> Documentation </h2>
            <a className="block mb-2 underline" href="https://medium.com/@charlottebotermans/elog-a-time-tracking-software-developed-based-on-user-research-487e1db2f920" target="_blank" rel="noreferrer"> Medium report </a>
            <h2 className = { subtitle }> Year </h2>
            <p className="mb-4">2019</p>
          </div>
        </div>

        <div className="max-w-screen-xl mx-auto mt-12 w-4/6 md:w-full col-span-3">
          <StaticImage className="w-6/6 md:w-full"
            src="../images/elog-home.png"
            placeholder="blurred"
            loading="eager"
          />
        </div>

        <div className="max-w-screen-xl mx-auto mt-12 w-4/6 md:w-full col-span-3 ">
          <StaticImage className="w-6/6 md:w-full"
            src="../images/elog-statoverview.png"
            placeholder="blurred"
            loading="eager"
          />
        </div>

        <div className="max-w-screen-xl mx-auto mt-12 w-4/6 md:w-full col-span-3">
          <StaticImage className="w-6/6 md:w-full"
            src="../images/elog-statdetail.png"
            placeholder="blurred"
            loading="eager"
          />
        </div>

        <div className="max-w-screen-xl mx-auto mt-12 w-4/6 md:w-full col-span-3">
          <StaticImage className="w-6/6 md:w-full"
            src="../images/elog-invoices.png"
            placeholder="blurred"
            loading="eager"
          />
        </div>

      </div>
      <Footer/>
    </div>
     
  
  )
}


export default DetailElogPage